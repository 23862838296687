import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Header from "../components/header"

import { StaticQuery, graphql } from "gatsby"
import Moment from "moment"
import ReactMarkdown from "react-markdown"

const RowOfSchedule = ({
  title,
  eventDate,
  titleLink,
  venue,
  thumbnailImage,
  text,
}) => (
  <Row
    style={{
      marginBottom: `3rem`,
    }}
  >
    <Col sm={12} md={4}>
      <Image
        src={thumbnailImage.file.url}
        alt={thumbnailImage.title}
        style={{
          width: `100%`,
        }}
      />
    </Col>
    <Col>
      {titleLink ? (
        <h3
          style={{
            marginBottom: `0.5rem`,
          }}
        >
          <a
            style={{
              color: `black`,
            }}
            href={titleLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {title}
          </a>
        </h3>
      ) : (
        <h3
          style={{
            marginBottom: `0.5rem`,
          }}
        >
          {title}
        </h3>
      )}

      <h4
        style={{
          marginBottom: `0.25rem`,
        }}
      >
        {eventDate}
      </h4>
      <h4>{venue}</h4>
      <ReactMarkdown source={text} />
    </Col>
  </Row>
)

const Schedule = () => (
  <StaticQuery
    query={graphql`
      query ScheduleQuery {
        contentfulSchedule {
          childContentfulScheduleInfoTextTextNode {
            id
          }
        }
        contentfulContentType {
          id
        }
        allContentfulSchedule {
          edges {
            node {
              thumbnailImage {
                file {
                  url
                }
                title
              }
              dateOfEvent
              infoText {
                infoText
              }
              title
              titleLink
              venue
              contentful_id
            }
          }
        }
      }
    `}
    render={data => {
      const schedules = data.allContentfulSchedule.edges.map(({ node }) => {
        node.dateOfEvent = new Moment(node.dateOfEvent)
        return node
      })
      schedules.sort((a, b) => a.dateOfEvent.diff(b.dateOfEvent))
      const priorEvents = schedules.filter(a =>
        a.dateOfEvent.isBefore(Moment())
      )
      priorEvents.reverse()
      const futureEvents = schedules.filter(a =>
        a.dateOfEvent.isAfter(Moment())
      )

      return (
        <Layout>
          <SEO title="Schedule" />
          <Header title="Schedule" />
          <Container
            fluid="md"
            style={{
              padding: `0 2rem`,
            }}
          >
            {(futureEvents || []).map(
              ({
                dateOfEvent,
                infoText,
                thumbnailImage,
                title,
                titleLink,
                venue,
                contentful_id,
              }) => (
                <RowOfSchedule
                  title={title}
                  eventDate={dateOfEvent.format("LL, LT")}
                  venue={venue}
                  thumbnailImage={thumbnailImage}
                  key={contentful_id}
                  text={infoText.infoText}
                  titleLink={titleLink}
                />
              )
            )}

            <h1>Past</h1>
            {(priorEvents || []).map(
              ({
                dateOfEvent,
                infoText,
                thumbnailImage,
                title,
                titleLink,
                venue,
                contentful_id,
              }) => (
                <RowOfSchedule
                  title={title}
                  eventDate={dateOfEvent.format("LL")}
                  venue={venue}
                  thumbnailImage={thumbnailImage}
                  key={contentful_id}
                  text={infoText.infoText}
                  titleLink={titleLink}
                />
              )
            )}
          </Container>
        </Layout>
      )
    }}
  />
)

export default Schedule
