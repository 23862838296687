/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import typography from "../utils/typography"
import { Navigation } from "../components/navbar"

const Layout = ({ children }) => {
  return (
    <>
      <style>{typography.toString()}</style>
      <Navigation></Navigation>
      <main
        style={{
          marginTop: `6rem`,
          minHeight: `60vh`,
        }}
      >
        {children}
      </main>
      <footer
        style={{
          background: `rgb(20, 19, 19)`,
          color: `white`,
          width: `100%`,
          textAlign: `center`,
          padding: `7rem 0`,
          marginTop: `4rem`,
        }}
      >
        <h3 style={{ color: `white` }}>Represented by <a href="http://www.weaverartistmanagement.com.au/">Weaver Artist Management</a></h3>
        <h4 style={{ color: `white` }}>
          Copyright © 2019 - {new Date().getFullYear()} Markiyan Melnychenko | All rights reserved
        </h4>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
